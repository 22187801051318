@keyframes move_wave {
  0% {
    transform: translateX(0)translateZ(0)scaleY(1);
  }

  50% {
    transform: translateX(-25%)translateZ(0)scaleY(.55);
  }

  100% {
    transform: translateX(-50%)translateZ(0)scaleY(1);
  }
}

.waveWrapper {
  z-index: 0;
  margin: auto;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.waveWrapperInner {
  background-image: linear-gradient(to top, #86377b 20%, #27273c 80%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -1px;
  overflow: hidden;
}

.bgTop {
  z-index: 15;
  opacity: .5;
}

.bgMiddle {
  z-index: 10;
  opacity: .75;
}

.bgBottom {
  z-index: 5;
}

.wave {
  transform-origin: bottom;
  background-position: 0 bottom;
  background-repeat: repeat-x;
  width: 200%;
  height: 100%;
  position: absolute;
  left: 0;
}

.waveTop {
  background-size: 50% 100px;
}

.waveAnimation .waveTop {
  animation: 3s 1s move-wave;
}

.waveMiddle {
  background-size: 50% 120px;
}

.waveAnimation .waveMiddle {
  animation: 10s linear infinite move_wave;
}

.waveBottom {
  background-size: 50% 100px;
}

.waveAnimation .waveBottom {
  animation: 15s linear infinite move_wave;
}

/*# sourceMappingURL=index.4a3c4105.css.map */
